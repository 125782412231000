import React from "react";
import "../../resources/styles/loading.css";
/*************************************************************
 * REVISION 1.0
 * Fecha: 25/02/2023
 *
 * @description componente para manejo de cargando en la app
 * @author luis.ferrer
 * @version 1.0
 *
 *************************************************************/
const Loading = () => (
  <>
    <div className="container">
      <div className="row">
        <div className="animationload">
          <div className="osahanloading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Loading;
