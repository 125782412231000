/************************************************
 * REVISION 1.0
 * Fecha: 25/02/2022
 *
 * @description JS para manejo de labels ES
 * @author lferrer
 * @version 1.0
 *
 ***********************************************/
export const properties = {
  /** General */

  /** footer */
  com_crvsoft_label_footer_terms_conditions: "Términos y Condiciones",

  /** Page not found */
  com_crvsoft_label_page_404_title: "Oops!",
  com_crvsoft_label_page_404_subtitle: "Página no encontrada",

  /** Loading */
  com_crvsoft_label_loading_title: "Cargando",

  /** General label */
  com_crvsoft_label_yes: "Si",
  com_crvsoft_label_not: "No",
  /** General error labels in forms */
  com_crvsoft_label_field_required: "Campo requerido",
  com_crvsoft_label_field_format: "Formato invalido",

  /** General button labels */
  com_crvsoft_label_button_accept_title: "Aceptar",
  com_crvsoft_label_button_ok_title: "Ok",
  com_crvsoft_label_button_cancel_title: "Cancelar",
  com_crvsoft_label_button_save_title: "Registrar",
  com_crvsoft_label_button_update_title: "Editar",
  com_crvsoft_label_button_detail_title: "Ver detalle",
  com_crvsoft_label_button_changestate_title: "Cambiar estado",
  com_crvsoft_label_button_delete_title: "Eliminar",
  com_crvsoft_label_button_back_title: "Atras",
  com_crvsoft_label_button_back2_title: "Volver",
  com_crvsoft_label_button_add_title: "Agregar",
  com_crvsoft_label_button_search_title: "Buscar",
  com_crvsoft_label_button_clear_title: "Limpiar",
  com_crvsoft_label_button_login_title: "Iniciar Sesión",
  com_crvsoft_label_button_close_title: "Cerrar",
  com_crvsoft_label_button_view_title: "Ver",
  com_crvsoft_label_button_exit_title: "Salir",

  /** Email labels*/
  /** Email service message success*/
  com_crvsoft_label_email_send_success: "Envió exitoso, pronto lo contactaremos",
  /** Email service message error*/
  com_crvsoft_label_email_send_error: "Error en proceso de envío de correo electrónico",
  

};
