import { Suspense } from "react";
import { useOutlet } from "react-router-dom";
import { AppProvider } from "../../contexts/AppContext";
import { properties } from "../../utils/Properties_es";
import Loading from "../utils/Loading";
/*****************************************************************************
 * REVISION 1.0
 * Fecha: 26/02/2023
 *
 * @description componente template - context layout
 * @author crvsoft
 * @version 1.0
 *
 ****************************************************************************/
const ContextLayout = () => {
  const outlet = useOutlet();
  return (
    <Suspense
      fallback={<Loading title={properties.com_crvsoft_label_loading_title} />}
    >
      <AppProvider>{outlet}</AppProvider>
    </Suspense>
  );
};

export default ContextLayout;
